<template>
    <div class="wrapper">
      <div class="half">
        <div id="map" class="map">
            <div class="map-btn">
                <v-btn
                    class="ma-0 primary"
                    dark
                    @click="goToPage('/')"
                >
                    <v-icon
                    dark
                    left
                    >
                    mdi-map
                    </v-icon>View Full Map
                </v-btn>
            </div>
        </div>
      </div>
      <div class="half" >
        <div id="aerialMap" class="map pa-2">
            <!-- <v-card            
            
            >
              <template>
                <v-data-table
                    :headers="headers"
                    :items="newParam"
                    class="elevation-1"
                    dense
                    :items-per-page="30"
                    :hide-default-header="true"
                    :hide-default-footer="true"
                    v-scroll:#scroll-target="onScroll"
                    max-height='100px'
                >
                  <template v-slot:header="{ props: { headers } }">
                    <thead>
                      <tr>
                        <th v-for="h in headers" :key="h.text">
                          <span v-if="h.value == 'fullname'">{{ h.text }}</span>
                          <span v-else v-html="h.text + '<br>' + h.name + '<br>' + h.last_date"></span>
                        </th>
                      </tr>
                    </thead>
                  </template>
                    <template v-slot:[`item.calories`]="{ item }">
                    <v-chip
                        :color="getColor(item.calories)"
                        dark
                    >
                        {{ item.calories }}
                    </v-chip>
                    </template>
                </v-data-table>
              </template>
            </v-card> -->
            <template v-if="selectedStation == ''">
              <v-simple-table
                fixed-header
                :height="$vuetify.breakpoint.height - 100"
                class="headerDtSarawak"
              >
                <template v-slot:default>
                  <template>
                    <thead class="pa-2">
                      <tr>
                        <th v-for="h in headers" :key="h.text">
                          <span v-if="h.value == 'fullname'">{{ h.text }}</span>
                          <span v-else style="cursor:pointer !important;" @click="stationInformation(h.text)" v-html="h.text + '<br>' + h.name + '<br>' + new Date(h.last_date).toLocaleDateString('en-GB') + ', ' + new Date(h.last_date).toLocaleTimeString()"></span>
                        </th>
                      </tr>
                    </thead>
                  </template>
                  <tbody>
                    <tr
                      v-for="(item) in newParam"
                      :key="item.name"
                    >
                      <template v-if="item.reading == 'WQI'">
                        <!-- <td>{{ item.fullname }}</td> -->
                        <td 
                          v-for="(header) in headers"
                          :key="header.value"
                        >
                          <span v-if="header.value == 'fullname'">
                            {{ item.fullname }}
                          </span>

                          <span v-else>
                            <v-chip
                                :color="getColor(item[header.value])"
                                dark
                            >
                                {{ item[header.value] == '-1' || item[header.value] == null ? 'NA' : item[header.value] }}
                            </v-chip>
                          </span>
                          
                        </td>
                      </template>
                      <template v-else>
                        <td 
                          v-for="(header) in headers"
                          :key="header.value"
                        >
                          <span v-if="header.value == 'fullname'">
                            {{ item.fullname }}
                          </span>

                          <span v-else>
                            <!-- {{ item[header.value] == null ? item[header.value] : item.unit == null ? item[header.value] : item[header.value] == 'DOR' || item[header.value] == 'OUTL' ? item[header.value] : item[header.value] + ' ' + item.unit }} -->
                            <span v-if="item[header.value] == 'DOR' || item[header.value] == 'OUTL' || item[header.value] == 'C' || item[header.value] == 'M'" class="red--text">
                              {{ item[header.value] }}
                            </span>
                            <span v-else>
                              {{ item[header.value] }}
                            </span>
                          </span>
                          
                        </td>
                      </template>
                      
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>

            <template 
              v-else-if="selectedStation !== ''"              
            >
            <v-flex auto class="px-0">
              <v-card
                class="mx-auto station-info-card"
                max-height="93%"
                :width="$vuetify.breakpoint.width / 2 - 50"
                outlined
              >
                <v-card-title class="pa-1 primary">
                  <v-spacer></v-spacer>

                  <v-btn
                    dark
                    icon
                    x-small
                    @click="selectedStation = ''; displayDashboard()"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>

                <v-card-text class="primary" v-if="selectedStation !== ''">
                  <v-list-item three-line dark>
                    <v-list-item-avatar>
                      <v-img
                        max-height="32"
                        max-width="22"
                        alt=""
                        src="../assets/mapviewer/station pin.svg"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="text-overline" id="info_station_id">
                        STATION {{ selectedStation.stationid_a }}
                      </div>
                      <div id="info_location">{{ selectedStation.LOCATION }}</div>
                      <div>Last Updated:</div>
                      <div>{{ new Date(selectedStation.DATETIME).toLocaleDateString('en-GB') + ', ' + new Date(selectedStation.DATETIME).toLocaleTimeString() }}</div>
                    </v-list-item-content>

                    <v-list-item-content v-if="selectedStation.param_wqi_class === 'I'">
                      <div class="reading-info-card reading-blue">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi }}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="selectedStation.param_wqi_class === 'II'"
                    >
                      <div class="reading-info-card reading-green">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi }}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="selectedStation.param_wqi_class === 'III'"
                    >
                      <div class="reading-info-card reading-yellow">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi }}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="selectedStation.param_wqi_class === 'IV'"
                    >
                      <div class="reading-info-card reading-orange">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi }}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="selectedStation.param_wqi_class === 'V'"
                    >
                      <div class="reading-info-card reading-red">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi }}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                    <v-list-item-content v-else>
                      <div class="reading-info-card reading-grey">
                        <div class="text-h4" id="info_reading">
                          {{ selectedStation.param_wqi  == '-1' ? 'NA' : selectedStation.param_wqi}}
                        </div>
                        <div class="text-h6" id="info_class">
                          CLASS {{ selectedStation.param_wqi_class }}
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>

                <template>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header>WQI PARAMETER</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="pa-0"></th>
                                  <th class="text-left">Parameter</th>
                                  <th class="text-left">Value & Unit</th>
                                  <th class="text-left">Sub Index</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(value, key) in parameter" :key="key">
                                  <td class="pa-0">
                                    <span
                                      v-if="value.class == 'I'"
                                      class="param-class-circle reading-blue"
                                    ></span>
                                    <span
                                      v-if="value.class == 'II'"
                                      class="param-class-circle reading-green"
                                    ></span>
                                    <span
                                      v-if="value.class == 'III'"
                                      class="param-class-circle reading-yellow"
                                    ></span>
                                    <span
                                      v-if="value.class == 'IV'"
                                      class="
                                        param-class-circle
                                        reading-orange
                                        alert-class
                                      "
                                    ></span>
                                    <span
                                      v-if="value.class == 'V'"
                                      class="param-class-circle reading-red alert-class"
                                    ></span>
                                    <span
                                      v-if="value.class == 'NA'"
                                      class="param-class-circle reading-grey"
                                    ></span>
                                    <span v-if="value.class == undefined"></span>
                                  </td>
                                  <td class="pr-0">{{ value.fullname }}</td>
                                  <td v-if="value.fullname == 'Water Quality Index'">
                                    {{
                                      value.value == null || value.value.trim() == '-1' ? 'NA' : value.value 
                                    }}
                                  </td>
                                  <td v-else>
                                    <!-- {{
                                      value.value == null
                                        ? ""
                                        : value.unit == null
                                        ? value.value
                                        : value.value + " " + value.unit
                                    }} -->
                                    <span v-if="value.value.trim() == 'DOR' || value.value.trim() == 'OUTL' || value.value.trim() == 'C' || value.value.trim() == 'M'" class="red--text">
                                      {{ value.value }}                                      
                                    </span>
                                    <span v-else>
                                      {{ value.value }}
                                    </span>
                                  </td>
                                  <td>
                                    {{ value.sub_index != null ? value.sub_index : "" }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header>STATION DETAILS</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list class="transparent">
                          <v-list-item dense>
                            <v-list-item-title>Station ID: </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedStation.stationid_a }}
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-title>Station Location: </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedStation.LOCATION }}
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-title>Longitude: </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedStation.LONGITUDE }}
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-title>Latitude: </v-list-item-title>

                            <v-list-item-subtitle>
                              {{ selectedStation.LATITUDE }}
                            </v-list-item-subtitle>
                          </v-list-item>
                          <v-list-item dense>
                            <v-list-item-title>Station Picture: </v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-row>
                              <v-col
                                class="d-flex child-flex"
                                cols="6"
                                v-if="selectedStation.STATION_PICTURE !== ''"
                              >
                                <v-img
                                  :src="selectedStation.STATION_PICTURE.replace('sarawakdir','publicdir')"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-col>
                              <v-col
                                class="d-flex child-flex"
                                cols="6"
                                v-if="selectedStation.DEPLOYMENT_PICTURE !== ''"
                              >
                                <v-img
                                  :src="selectedStation.DEPLOYMENT_PICTURE.replace('sarawakdir','publicdir')"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-col>
                              <v-col
                                class="d-flex child-flex"
                                cols="6"
                                v-if="selectedStation.UPSTREAM_PICTURE !== ''"
                              >
                                <v-img
                                  :src="selectedStation.UPSTREAM_PICTURE.replace('sarawakdir','publicdir')"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-col>
                              <v-col
                                class="d-flex child-flex"
                                cols="6"
                                v-if="selectedStation.DOWNSTREAM_PICTURE !== ''"
                              >
                                <v-img
                                  :src="selectedStation.DOWNSTREAM_PICTURE.replace('sarawakdir','publicdir')"
                                  aspect-ratio="1"
                                  class="grey lighten-2"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-col>
                            </v-row>
                          </v-list-item>
                        </v-list>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-card>
            </v-flex>
              
            </template>
            
        </div>
      </div>
    </div>
</template>

<script>
import "ol/ol.css";

// import * as ol from 'ol';
import Map from "ol/Map";
import View from "ol/View";
import {defaults as Interaction} from 'ol/interaction';
import { defaults as defaultControls, ScaleLine } from "ol/control";
import {
  Tile as TileLayer,
  Vector as VectorLayer,
  Group as GroupLayer,
} from "ol/layer";
import { OSM, Vector as VectorSource } from "ol/source";
import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import Overlay from "ol/Overlay";

// Axios for fetching data
import axios from "axios";

export default {
  data: () => ({
    displayCard: false,
    displayCardMap: false,
    displayInformation: false,
    station_id: "",
    overlayInsert: false,
    map: null,
    view: null,
    // extent: [112.022942, 1.711069, 114.712705, 3.058041],
    extent: [109.018853, 0.671687, 116.043022, 5.368743],
    items: [],
    selectedItem: 1,
    station_status: [
      {
        text: "Active",
        img: require("@/assets/mapviewer/station_icon_green.svg"),
      },
      {
        text: "Inactive",
        img: require("@/assets/mapviewer/station_icon_grey.svg"),
      },
      {
        text: "Transmission Delay",
        img: require("@/assets/mapviewer/station_icon_orange.svg"),
      },
    ],
    wqi_class: [
      { text: "Class I", img: require("@/assets/mapviewer/wqi_icon_blue.svg") },
      {
        text: "Class II",
        img: require("@/assets/mapviewer/wqi_icon_green.svg"),
      },
      {
        text: "Class III",
        img: require("@/assets/mapviewer/wqi_icon_yellow.svg"),
      },
      {
        text: "Class IV",
        img: require("@/assets/mapviewer/wqi_icon_orange.svg"),
      },
      { text: "Class V", img: require("@/assets/mapviewer/wqi_icon_red.svg") },
      {
        text: "Class N/A",
        img: require("@/assets/mapviewer/wqi_icon_grey.svg"),
      },
    ],
    baseMapLayer: {
      googleStreetMap: new TileLayer({
        title: "Google Street",
        source: new OSM({
          url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
          crossOrigin: "anonymous",
        }),
        visible: true,
        type: "base",
      }),
    },
    pointStyle: {
      default: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#3381ff",
          }),
          stroke: new Stroke({
            color: "#0242ab",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
      selected: new Style({
        image: new Circle({
          fill: new Fill({
            color: "#00ffff",
          }),
          stroke: new Stroke({
            color: "#003300",
            width: 1.25,
          }),
          radius: 8,
        }),
      }),
    },
    stationDetails: "",
    parameterDetails: "",
    selectedFeature: "",
    wqi_parameter: [
      {
        parameter: [],
        subIndex: [],
      },
    ],
    pointOfInterestDetails: "",
    pointOfInterestVisible: false,
    testParameterDetails: [],
    parameter: [],
    selectedPOI: "",

    // headers: [
    //       // {
    //       //   text: 'Dessert (100g serving)',
    //       //   align: 'start',
    //       //   sortable: false,
    //       //   value: 'name',
    //       // },
    //       { text: 'Parameters', value: 'fullname', name: '' },
    //       { text: 'CMS01', value: 'CMS01', name: '' },
    //       { text: 'CMS02 ', value: 'CMS02',name: '' },
    //       { text: 'CMS03', value: 'CMS03', name: '' },
    //       { text: 'CMS04', value: 'CMS04', name: '' },
    //     ],
    desserts: [],

    newParam: [],
    selectedStation: "",
    stationList: [],

    newHeader: [{ text: 'Parameters', value: 'fullname', name: '' }]


  }),
  computed: {
    headers() {
      let headers = [{ text: 'Parameters', value: 'fullname', name: '' }]
      for(let i = 0 ; i < this.stationList.length; i++){
        headers.push({
              text: this.stationList[i],
              value: this.stationList[i],
              name: ''
            })
      }

      for(let i = 0; i < this.stationDetails.length; i++){
        for(let j = 0; j < headers.length; j++){
          if(headers[j].value == this.stationDetails[i].stationid_a){
            headers[j].name = this.stationDetails[i].LOCATION;
            headers[j]['last_date'] = this.stationDetails[i].DATETIME;
          }
        }
      }
      // console.log(headers);
      return headers;

    }
  },

  methods: {

    getStationInfo() {
      axios
        .get("https://temptunnel1.shaz.my/api/cms/reading", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.stationDetails = response.data;
          this.addMapMarker();

          let stationData = response.data;
          this.stationList = [];
          for(let i = 0; i < stationData.length; i++){
            this.stationList.push(stationData[i].stationid_a);

            // for(let j = 0; j < this.headers.length; j++){
            //   if(this.headers[j].value == stationData[i].stationid_a){
            //     this.headers[j].name = stationData[i].LOCATION;
            //     this.headers[j]['last_date'] = stationData[i].DATETIME;
            //   }
            // }
          }
          this.stationList.sort();
          // console.log(this.stationList);

          let stationParam;

          axios.get( "https://temptunnel1.shaz.my/api/cms/params?stationid=CMS02",
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            stationParam = response.data;
            
            let newParam = [];

            for(let i = 0; i < stationData.length; i++) {
              newParam = [];
              let stationReading = stationData[i]
              for(let j = 0; j < stationParam.length; j++){
                if(!stationParam[j].reading.includes('SI')){
                  for(let k in stationReading){

                    if(k == stationParam[j].reading){
                      stationReading.stationid_a;
                      if (k == 'TEMPERATURE'){
                        if (stationReading[k] == null){
                          if(stationReading['TEMP_FLAG'] == null){
                            stationParam[j][stationReading.stationid_a] = 'NA';
                          } else {
                            stationParam[j][stationReading.stationid_a] = stationReading['TEMP_FLAG'];
                          }
                        } else {
                          let unit = stationParam[j]['unit'] == null ? '' : stationParam[j]['unit']
                          stationParam[j][stationReading.stationid_a] = stationReading[k] + ' ' + unit;
                        }
                      } else {
                        if (stationReading[k] == null){
                          if(stationReading[k+'_FLAG'] == null){
                            stationParam[j][stationReading.stationid_a] = 'NA';
                          } else {
                            stationParam[j][stationReading.stationid_a] = stationReading[k+'_FLAG'];
                          }

                        } else {
                          let unit = stationParam[j]['unit'] == null ? '' : stationParam[j]['unit']
                          stationParam[j][stationReading.stationid_a] = stationReading[k] + ' ' + unit;
                        }
                      } 
                       
                      
                      stationParam[j]["class"] = ''
                      newParam.push(stationParam[j]);
                    }

                    if(k == 'param_wqi'){
                      if(stationParam[j].reading == 'WQI'){
                        stationReading.stationid_a; 
                        stationParam[j][stationReading.stationid_a] = stationReading[k];
                        stationParam[j]["class"] = ''
                        newParam.push(stationParam[j]);
                      }
                    }
                  }
                }
                
              }
            }
            // console.log(newParam);
            // sort parameter without WQI  
            // WQI 0-7
            // 8- 18
            // START SORTING FUNCTION
            let sortParam = [];
            let tempSort = [];
            for(let i = 0 ;i<newParam.length;i++){
              if(i>=1){
                // console.log(newParam[i])
                tempSort.push(newParam[i])
              }else{
                sortParam.push(newParam[i])
              }
            }
            // tempSort.sort(function(a, b){
            //     if(a.fullname < b.fullname) { return -1; }
            //     if(a.fullname > b.fullname) { return 1; }
            //     return 0;
            // })

            let newArrangedData = ['Temperature', 'pH', 'Dissolved Oxygen (Saturation)', 'Dissolved Oxygen (Concentration)', 'Electrical Conductivity', 'Salinity', 'Ammonium', 'Ammoniacal Nitrogen',
                                    'Turbidity', 'Total Suspended Solids', 'Biochemical Oxygen Demand', 'Chemical Oxygen Demand', 'Dissolved Organic Carbon', 'Total Organic Carbon', 'Escherichia Coli', 'Chlorophyll-a', 'Solar battery',
                                    'Tryphptophan','Colored Dissolved Organic Matter'
                                  ];
            for(let i = 0;i<newArrangedData.length;i++){
              for(let j = 0; j < tempSort.length; j++){
                if(tempSort[j].fullname == newArrangedData[i]){
                  sortParam.push(tempSort[j])
                }
              }
            }
            // END SORTING FUNCTION
            this.newParam = sortParam;
            
          })
          .catch((error) => {
            console.log(error);
          });

          

          

          // this.todo();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getParameterInfo() {
      axios
        .get("https://temptunnel1.shaz.my/api/cms/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.parameterDetails = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    initiateMap() {
      var source = new VectorSource();
      var vector = new VectorLayer({
        source: source,
      });

      var baseMap = new GroupLayer({
        layers: [
          this.baseMapLayer.googleStreetMap,
        ],
      });

      this.map = new Map({
        controls: defaultControls({
          zoom: false,
        }),
        // .extend([
        //     new ScaleLine({
        //         units: 'metric',
        //     }),
        // ]),
        // interactions: Interaction(
        //   {
        //     mouseWheelZoom:false, 
        //     shiftDragZoom:false, 
        //     doubleClickZoom: false, 
        //     dragPan: false
        //   }),
        target: "map",
        layers: [baseMap],
        view: new View({
          projection: "EPSG:4326",
          zoom: 1,
        }),
      });

      this.view = this.map.getView();
      this.view.fit(this.extent);
    },
    addMapMarker() {
      for (let i = 0; i < this.stationDetails.length; i++) {
        var marker = new Feature({
          geometry: new Point([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]),
          description: this.stationDetails[i],
        });
        var vectorSource = new VectorSource({
          features: [marker],
        });

        var markerVectorLayer = new VectorLayer({
          source: vectorSource,
          style: new Style({
            image: new Icon({
              crossOrigin: "anonymous",
              src: this.stationDetails[i].green == 'true' ? this.station_status[0].img : this.stationDetails[i].grey == 'true' ? this.station_status[1].img : this.stationDetails[i].yellow == 'true' ? this.station_status[2].img : '',
              scale: 1.5,
            }),
          }),
        });
        this.map.addLayer(markerVectorLayer);
      }
      this.addMapOverlayReading();
    },
    addMapOverlayReading() {
      // console.log(this.stationDetails);

      for (let i = 0; i < this.stationDetails.length; i++) {
        // let elementId = "reading_" + this.stationDetails[i].stationid_a;

        // let div = document.createElement("DIV");
        // div.setAttribute("id", elementId);

        // if (this.stationDetails[i].param_wqi_class == "I") {
        //   div.setAttribute("class", "reading-div reading-blue");
        // } else if (this.stationDetails[i].param_wqi_class == "II") {
        //   div.setAttribute("class", "reading-div reading-green");
        // } else if (this.stationDetails[i].param_wqi_class == "III") {
        //   div.setAttribute("class", "reading-div reading-yellow");
        // } else if (this.stationDetails[i].param_wqi_class == "IV") {
        //   div.setAttribute("class", "reading-div reading-orange alert-class");
        // } else if (this.stationDetails[i].param_wqi_class == "V") {
        //   div.setAttribute("class", "reading-div reading-red alert-class");
        // } else {
        //   div.setAttribute("class", "reading-div reading-grey");
        // }

        // div.innerHTML =
        //   this.stationDetails[i].param_wqi_class == "NA"
        //     ? "N/A"
        //     : this.stationDetails[i].param_wqi;
        // document.getElementById("map").appendChild(div);

        // var overlay = new Overlay({
        //   element: document.getElementById(elementId),
        //   id: this.stationDetails[i].stationid_a,
        //   offset: [0, -24],
        //   positioning: "bottom-center",
        // });
        // overlay.setPosition([
        //   this.stationDetails[i].LONGITUDE,
        //   this.stationDetails[i].LATITUDE,
        // ]);
        // this.map.addOverlay(overlay);

        let labelId = "label_" + this.stationDetails[i].stationid_a;

        let label = document.createElement("div");

        label.setAttribute("id", labelId);
        let position = [];

        if(this.stationDetails[i].stationid_a == 'CMS03' || this.stationDetails[i].stationid_a == 'CMS02'){
          label.setAttribute("class", "box arrow-right");
          this.stationDetails[i].stationid_a == 'CMS03' ? position = [-80, -40] : position = [-87, -45];
        } else if(this.stationDetails[i].stationid_a == 'CMS01' || this.stationDetails[i].stationid_a == 'CMS04') {
          label.setAttribute("class", "box arrow-left");
          this.stationDetails[i].stationid_a == 'CMS01' ? position = [65, -45] : position = [80, -40];
        } else {
          label.setAttribute("class", "box arrow-bottom");
          position = [5, -102];
        }

        label.innerHTML = this.stationDetails[i].stationid_a + '<br>' + this.stationDetails[i].LOCATION;
        document.getElementById("map").appendChild(label);

        var overlayLabel = new Overlay({
          element: document.getElementById(labelId),
          id: "label_" + this.stationDetails[i].stationid_a,
          offset: position,
          positioning: "top-center",
        });

        overlayLabel.setPosition([
          this.stationDetails[i].LONGITUDE,
          this.stationDetails[i].LATITUDE,
        ]);
        this.map.addOverlay(overlayLabel);
      }
      // this.overlayInsert = true;
    },

    getColor (wqi) {

        if(wqi >= 92.7) {
            return '#3d8af7';
        } else if (wqi >= 76.5 && wqi < 92.7) {
            return '#7cde6b';
        } else if (wqi >= 51.9 && wqi < 76.5) {
            return '#ffdd03';
        } else if (wqi >= 31.0 && wqi < 51.9) {
            return '#ffa834';
        } else if (wqi > 0 && wqi < 31.0) {
            return '#ff3823';
        } else {
            return '#cccccc';
        }

    },

    stationInformation(stationId){

      for(let i = 0; i < this.stationDetails.length; i++){
        if(stationId.trim() == this.stationDetails[i].stationid_a){
          this.selectedStation = this.stationDetails[i];
        }
      }
      
      this.map.getView().setZoom(15);
      this.map.getView().centerOn(
        [this.selectedStation.LONGITUDE, this.selectedStation.LATITUDE],
        [100, 100],
        [100, 50]
      );
      
      this.wqi_parameter = {
        parameter: [],
        subIndex: [],
      };

      this.testParameterDetails = [];
      let data = this.selectedStation;

      for (let k in data) {
        if (k.includes("param_")) {
          if (k.includes("si")) {
            let obj = {};
            obj["name"] =
              k.replace("param_si_", "") == "ph"
                ? "SI_pH"
                : k.replace("param_si_", "SI_").toString().toUpperCase();
            obj["value"] = this.selectedStation[k];
            this.testParameterDetails.push(obj);
          } else if (k.includes("class")) {
            let obj = {};
            obj["name"] = k.toString().toUpperCase();
            obj["value"] = this.selectedStation[k];
            this.testParameterDetails.push(obj);
          } else {
            let str =
              k.replace("param_", "") === "ph"
                ? "pH"
                : k.replace("param_", "") === "do_sat"
                ? "Dissolve Oxygen"
                : k.replace("param_", "").toString().toUpperCase();
            this.wqi_parameter.parameter[str] = this.selectedStation[k];

            let obj = {};
            obj["name"] =
              k.replace("param_", "") == "ph"
                ? "pH"
                : k.replace("param_", "").toString().toUpperCase();
            obj["value"] = this.selectedStation[k];
            this.testParameterDetails.push(obj);
          }
        } else {
          if(k == 'TEMPERATURE'){
            let obj = {};
            obj["name"] = k;
            obj["value"] = this.selectedStation[k];
            obj["flag"] = this.selectedStation['TEMP_FLAG'];
            this.testParameterDetails.push(obj);
          } else {
            let obj = {};
            obj["name"] = k;
            obj["value"] = this.selectedStation[k];
            obj["flag"] = this.selectedStation[k+'_FLAG'];
            this.testParameterDetails.push(obj);
          }
        }
      }

      let arrangedData = this.testParameterDetails;

      // console.log(this.testParameterDetails);


      let that = this;
      that.parameter = [];
      let subIndex = [];

      axios
        .get(
          "https://temptunnel1.shaz.my/api/cms/params?stationid=" +
            this.selectedStation.stationid_a,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);

          for (let k in arrangedData) {
            response.data.forEach(function (res) {
              if (arrangedData[k].name == res.reading) {
                let value = arrangedData[k].value == null && arrangedData[k].flag == null ? 'NA' : arrangedData[k].value != null ? arrangedData[k].value : arrangedData[k].flag != null ? arrangedData[k].flag : 'NA'
                let unit = res['unit'] == null ? '' : res['unit']
                arrangedData[k].flag == 'DOR' || arrangedData[k].flag == 'OUTL' || arrangedData[k].flag == 'C' || arrangedData[k].flag == 'M' ? unit = '' : ''
                unit == 'Index' ? unit = '' : ''
                value == 'NA' ? unit = '' : ''
                res["value"] = value + ' ' + unit;
                res["sub_index"] = null;
                if (!res.reading.includes("SI_")) {
                  that.parameter.push(res);
                } else {
                  subIndex.push(res);
                }
              }
            });

            for (let j in subIndex) {
              that.parameter.forEach(function (res) {
                if (
                  res.reading == subIndex[j].reading.replace("SI_", "")
                ) {
                  res["sub_index"] = subIndex[j].value;
                }
              });
            }
            var seenNames = {};
            that.parameter = that.parameter.filter(function (currentObj) {
              if (currentObj.reading in seenNames) return false;
              else {
                seenNames[currentObj.reading] = true;
                return true;
              }
            });

            for (let l in arrangedData) {
              that.parameter.forEach(function (res) {
                if (
                  "PARAM_" + res.reading.toUpperCase() + "_CLASS" ==
                  arrangedData[l].name
                ) {
                  res["class"] = arrangedData[l].value;
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    displayDashboard(){
      this.view = this.map.getView();
      this.view.fit(this.extent, {
        duration: 500,
      });
    }
  },

  mounted() {
    this.getStationInfo();
    this.getParameterInfo();
    this.initiateMap();

  },
};
</script>

<style lang="scss" scoped>
@import '~scss/main';
.height-list {
  height: 5px;
}

.map {
    width: 100%;
    height: 100%;
}

.wrapper {
    display: flex;
    height: 100%;
}
.half {
    padding: 0;
    width: 50%;
    float: left;
}

// #map {
//   position: absolute;
//   margin: 0;
//   padding: 0;
//   height: 100%;
//   width: 100%;
// }

.map-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;
}

.map-icon-btn {
  margin-block-end: 10px;
}

.info-card {
  position: absolute;
  top: 66px;
  left: 80px;
  z-index: 1;
  overflow: auto;
}

.info-card > div:nth-child(2) {
  padding: 12px 16px;
}

.station-info-card {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  overflow: auto;
}

.poi-description {
  white-space: normal !important;
}

.param-class-circle {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

::v-deep tbody tr:nth-of-type(even) {
   background-color: rgba(0, 0, 0, .05);
}

::v-deep .reading-info-card {
  padding: 15px;
  text-align: center;
}

::v-deep .reading-div {
  padding-left: 6px;
  padding-right: 6px;
  z-index: 2;
}

::v-deep .station-label {
  text-shadow: 2px 2px 8px #fff;
}

::v-deep .reading-blue {
  border: 2px solid #fff;
  color: #fff;
  background-color: #3d8af7;
}

::v-deep .reading-green {
  border: 2px solid #fff;
  background-color: #7cde6b;
}

::v-deep .reading-yellow {
  border: 2px solid #fff;
  background-color: #ffdd03;
}

::v-deep .reading-orange {
  border: 2px solid #fff;
  background-color: #ffa834;
}

::v-deep .reading-red {
  border: 2px solid #fff;
  color: #fff;
  background-color: #ff3823;
}

::v-deep .reading-grey {
  border: 2px solid #fff;
  background-color: #cccccc;
}

::v-deep .alert-class {
  animation: blink 1.5s infinite;
}

::v-deep .box {
  // width: 180px;
  height: auto;
  background-color: #fff;
  color: black;
  padding: 10px;
  position: relative;
  font-size: 14px !important;
  // margin: 40px;
}

::v-deep .box.arrow-right:after {
  content: " ";
  position: absolute;
  right: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid #fff;
  border-bottom: 15px solid transparent;
}

::v-deep .box.arrow-left:after {
  content: " ";
  position: absolute;
  left: -15px;
  top: 15px;
  border-top: 15px solid transparent;
  border-right: 15px solid #fff;
  border-left: none;
  border-bottom: 15px solid transparent;
}

::v-deep .box.arrow-bottom:after {
  content: " ";
  position: absolute;
  right: 30px;
  bottom: -15px;
  border-top: 15px solid #fff;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}

@keyframes blink {
  0% {
    transform: scale(1);
    opacity: 0.25;
  }
  50% {
    transform: scale(1, 1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.25;
  }
}
</style>


